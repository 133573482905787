<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <validation-observer ref="addCourseFormvalidate">
      <b-form class="mt-2" style="width: 80%" @submit.prevent="save">
        <b-row>
          <b-col cols="12">
            <b-form-group label="الأحكام والشروط:" label-for="blog-content" class="mb-2">
              <validation-provider #default="{ errors }" name="Description" rules="required">
                <QuillEditor
                  :editorRef="'descriptionEditor'"
                  v-model="addForm.description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="الكلمات المفتاحية" label-for="blog-content" class="mb-2">
              <b-form-tags v-model="addForm.meta_keywords" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="العنوان الاضافي" label-for="blog-edit-title" class="mb-2">
              <validation-provider #default="{ errors }" name="Meta Title" rules="required">
                <b-form-input id="blog-edit-title" v-model="addForm.meta_title" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="الوصف الاضافي" label-for="blog-edit-slug" class="mb-2">
              <validation-provider #default="{ errors }" name="Meta Description" rules="required">
                <b-form-input id="blog-edit-slug" v-model="addForm.meta_description" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="mt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              حفظ
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { required, email } from "@validations";
import Ripple from "vue-ripple-directive";
import QuillEditor from "@core/components/editor/Editor";
import { ref, reactive } from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";

export default {
  setup(props) {
    const Form = ref({
      description: '',
      meta_title: "",
      meta_description: "",
      meta_keywords: [],
    });
    const addForm = reactive(Form);

    store.dispatch('setting/getTerms')
      .then(response => {
        if (response?.data.data) {
          Form.value = response?.data.data;
          Form.value.meta_keywords = response.data.data.keywords.split(',');
        }
      });

    const addFormalidate = ref();

    const save = () => {
      const formData = new FormData();
      formData.append("description", addForm.description);
      formData.append("meta_title", addForm.meta_title);
      formData.append("meta_description", addForm.meta_description);
      formData.append("meta_keywords", addForm.meta_keywords.join(','));

      store.dispatch('setting/updateTerms', formData)
        .then(response => {
          Vue.swal({
            icon: "success",
            title: "تم التحديث",
            confirmButtonText: "حسنا",
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          });
        });
    };

    function example_image_upload_handler(blobInfo, success, failure, progress) {
      let xhr, formData;
      let token = localStorage.getItem('token');
      xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open('POST', 'https://ar.lpc.prokoders.dev/api/upload_image_tiny_mce');

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100);
      };
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      xhr.onload = function () {
        let json;
        if (xhr.status === 403) {
          failure('HTTP Error: ' + xhr.status, { remove: true });
          return;
        }
        if (xhr.status < 200 || xhr.status >= 300) {
          failure('HTTP Error: ' + xhr.status);
          return;
        }
        json = JSON.parse(xhr.responseText);
        if (!json || typeof json.location != 'string') {
          failure('Invalid JSON: ' + xhr.responseText);
          return;
        }
        success(json.location);
      };
      xhr.onerror = function () {
        failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
      };
      formData = new FormData();
      formData.append('image', blobInfo.blob(), blobInfo.filename());
      xhr.send(formData);
    }

    return {
      example_image_upload_handler,
      addForm,
      addFormalidate,
      Form,
      save,
      required,
      email
    };
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    QuillEditor,
  },

  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
